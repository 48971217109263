<template>
  <div>
    <google-map-pins :locations="locations"></google-map-pins>
  </div>
</template>

<script>
import GoogleMapPins from '@/components/GoogleMapPins.vue';
export default {
  name: 'map2',
  components: {
    GoogleMapPins,
  },
  data() {
    return {
      locations: [
        { lat: 13.772188, long: 100.480889, title: 'Generator A' },
        { lat: 13.727757, long: 100.543764, title: 'Garden' },
      ],
    };
  },
};
</script>

<style scoped></style>
